import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';

import type { Site } from '@/common/types';
import URLS from '@/lib/urls';

import Button from '@sgds/component-button';

import {
  SitesDisplayStatus,
  getSitesDisplayStatus,
} from '../../helpers/main_page_helpers';

const ProductPageComponent: FC<{
  sites: Array<Site> | Error;
  hasLicense: boolean;
}> = ({ sites, hasLicense }) => {
  const sitesAccessStatus = getSitesDisplayStatus(sites);

  const router = useRouter();
  const { t } = useTranslation(['mainPage', 'footer']);

  if (sitesAccessStatus === SitesDisplayStatus.EMPTY && !hasLicense) {
    return (
      <Button
        label={t('goto-product-page')}
        onClick={() => router.push(URLS.PRODUCT_URL)}
      />
    );
  }

  return null;
};

export default ProductPageComponent;
