import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';

import { Site } from '@/common/types';
import URLS from '@/lib/urls';

import { Card } from '@sgds/component-card';
import { SvgIcon } from '@sgds/component-svg-icon';
import { Text, TextPresentation } from '@sgds/component-text';
import { TextLink } from '@sgds/component-text-link';

import styles from './sitesList.module.scss';

const Title: FC = () => {
  const { t } = useTranslation('mainPage');

  return (
    <TextPresentation variant={TextPresentation.variants.SUBHEAD_1}>
      {t('select-site')}
    </TextPresentation>
  );
};

const Note: FC = () => {
  const { t } = useTranslation('mainPage');

  return (
    <div className={styles.missingSitesNote}>
      <span className={styles.helpStyle}>
        <SvgIcon iconId="help16" />
      </span>
      <TextPresentation
        variant={TextPresentation.variants.BODY}
        className={styles.contentNote}
      >
        {t('missing-sites-note')}{' '}
        <TextLink
          href={t('common:support-url')}
          label={t('support-site-label')}
          target="_blank"
        />
        .
      </TextPresentation>
    </div>
  );
};

const SiteCard = ({ site }: { site: Site }) => {
  const { url } = site;

  return (
    <a
      href={`${URLS.PROTOCOL + url}/forge/init_authentication`}
      rel="noreferrer"
    >
      <Card isInteractive className={styles.cardStyle}>
        <div className={styles.cardSiteAddress}>
          <Text className={styles.cardLinkStyle}>{url}</Text>
        </div>
        <div className={styles.filler} />
        <div className={styles.cardCaretRight}>
          <SvgIcon iconId="caretRightDense" />
        </div>
      </Card>
    </a>
  );
};

const SitesList: FC<{ sites: Array<Site> }> = ({ sites }) => (
  <div className={styles.container}>
    <Title />
    <div className={styles.cardSpace}>
      {sites.map(site => (
        <SiteCard site={site} key={site.id} />
      ))}
    </div>
    <Note />
  </div>
);

export { SitesList, Note as SitesNote };
