import { AxiosError } from 'axios';
import { useTranslation } from 'next-i18next';
import React from 'react';

import * as URLS from '@/lib/urls';

import { SvgIcon } from '@sgds/component-svg-icon';
import { TextPresentation } from '@sgds/component-text';
import { TextLink } from '@sgds/component-text-link';

import styles from './errorSitesList.module.css';

const ErrorSitesList = ({ error }: { error: AxiosError }): JSX.Element => {
  const { t } = useTranslation('mainPage');

  const abortedByUser = error?.message === 'Request aborted';
  const detailMsg = abortedByUser ? 'error-interrupted' : 'error-detail';

  return (
    <div className={styles.container}>
      <div className={styles.errorHeader}>
        <span className={styles.alertStyle}>
          <SvgIcon iconId="alert" style={{ width: 24, height: 24 }} />
        </span>
        <TextPresentation isStrong variant={TextPresentation.variants.BODY}>
          {t('error-message')}
        </TextPresentation>
      </div>
      <TextPresentation
        variant={TextPresentation.variants.BODY}
        className={styles.errorContent}
      >
        {t('error-remark')}
      </TextPresentation>
      <TextPresentation
        variant={TextPresentation.variants.BODY}
        className={styles.errorContent}
      >
        {t(detailMsg, { status: error?.response?.status || 404 })}{' '}
        {!abortedByUser && (
          <TextLink
            href={URLS.SUPPORT_URL}
            label="support site"
            target="_blank"
          />
        )}
        {!abortedByUser && '.'}
      </TextPresentation>
    </div>
  );
};

export default ErrorSitesList;
