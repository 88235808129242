import axios from 'axios';
import newrelic from 'newrelic';
import {
  GetServerSideProps,
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
} from 'next';
// https://next-auth-git-canary.nextauthjs.vercel.app/getting-started/example
import { useRouter } from 'next/router';
import { Session } from 'next-auth';
import { getSession, signOut, useSession } from 'next-auth/client';
// https://github.com/isaachinman/next-i18next#serversidetranslations
import { UserConfig, i18n as serverI18n, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';
import { useCookie } from 'react-use';
import { SWRConfig } from 'swr';

import { AutodeskUser } from '@/common/types/forge_provider';
import { PageLocaleInfo } from '@/common/types/page_locale_info';
import useWaitForUpdatedProps from '@/components/hooks/useWaitForUpdatedProps';
import SignIn from '@/components/signIn';
import { getForgeIdFromSession } from '@/lib/forge_id_from_session';
import getBuildId from '@/lib/getBuildId';
import {
  getDefaultLocalizationInfo,
  getPageLocalizationInfo,
} from '@/lib/get_page_localization_info';
import logger from '@/lib/logger';
import getOAuthConfig from '@/lib/oauth2';
import { applyCookie } from '@/middlewares/next-universal-cookie';
import { clearCookiesOnLogout } from '@/middlewares/next-universal-cookie/logout-helper';
import { config as appConfig } from '@/root/config';
import i18nConfig from '@/root/next-i18next.config';
import * as Sentry from '@sentry/nextjs';

import MainPage from '../components/mainPage';

const fetcher = url => axios(url).then(res => res.data);
const log = logger('root');
const typedI18nConfig: UserConfig = i18nConfig;
const { cookiesConfig } = appConfig;
const checkRedirect = (req: GetServerSidePropsContext['req']): boolean =>
  !!req.cookies[cookiesConfig.nextjs.NEXT_LOCALE.name];

// https://github.com/isaachinman/next-i18next#serversidetranslations
export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  newrelic.setTransactionName('/');
  applyCookie(context.req, context.res);

  // Pokes PostgreSQL - request will be in-flight while waiting for the forgeId.
  const sessionPromise: Promise<Session> = getSession(context); // client-side useSession will use it - https://stackoverflow.com/a/63191786/3062039

  // the browser may have the cookie containing the forgeID even if the session has expired.
  // to avoid using stale information, force a database request
  // NOTE: the forgeId isn't exposed by the session object to be retrieved by the sessionPromise.
  const forgeId = await getForgeIdFromSession(
    context.req,
    /* skipCookieCachedForgeId */ true
  );

  const renegotiateLocale = !forgeId && checkRedirect(context.req);

  // on absence of a forgeId, clear all the cookies with session scope.
  // Clearing NEXT_LOCALE requires a renegotiation of the locale, which is done afterwards.
  if (!forgeId) {
    clearCookiesOnLogout(context.req, context.res);
  }

  // NEXT_LOCALE was previously set, redirect to '/' to force a renegotiation of the active language.
  if (renegotiateLocale) {
    // FIXME: sessionPromise still in flight, with no easy way to cancel it.
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  }

  // Pokes at Identity - request will be in-flight until the call to Promise.allSettled, below.
  const localeInfoPromise: Promise<PageLocaleInfo> = getPageLocalizationInfo(
    context,
    forgeId,
    log
  );

  const callbackUrl = process.env.NEXTAUTH_URL;
  const identityProviderSignOutUrl = `${
    getOAuthConfig().oAuth2Provider.signOutUrl
  }${callbackUrl}`;

  // All together now. Wait for all responses - do not fail fast.
  const results = await Promise.allSettled([localeInfoPromise, sessionPromise]);

  const [localeInfoObj, sessionObj] = results.map(p =>
    p.status === 'fulfilled' ? p.value : null
  );

  const localeInfo = localeInfoObj
    ? (localeInfoObj as PageLocaleInfo)
    : getDefaultLocalizationInfo(context);

  const session = sessionObj as Session;

  if (
    context.locale !== localeInfo.pageLocale &&
    context.locales.includes(localeInfo.pageLocale)
  ) {
    return {
      redirect: {
        destination: `/${localeInfo.pageLocale}/`,
        permanent: false,
      },
    };
  }

  log.debug(`pageLocale: ${localeInfo.pageLocale}`);

  if (serverI18n && serverI18n.language !== localeInfo.pageLocale) {
    await serverI18n.changeLanguage(localeInfo.pageLocale);
  }

  const props = {
    ...(await serverSideTranslations(
      localeInfo.pageLocale,
      ['common', 'signIn', 'mainPage', 'accessReleases', 'footer'],
      {
        reloadOnPrerender: process.env.NODE_ENV === 'development',
        ...typedI18nConfig,
      }
    )),
    session,
    identityProviderSignOutUrl,
    buildId: getBuildId(),
  };

  return {
    props,
  };
};

export function Page({
  identityProviderSignOutUrl,
  buildId,
}: InferGetServerSidePropsType<typeof getServerSideProps>): JSX.Element {
  const { t, i18n } = useTranslation(['common']);
  const router = useRouter();

  const [session, loading] = useSession();
  const [nextLocaleCookies, , deleteCookie] = useCookie('NEXT_LOCALE');

  const { refreshData, dataRefreshed } = useWaitForUpdatedProps();
  const [signingOut, setSigningOut] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async () => {
      if (signingOut) {
        await signOut({
          redirect: false,
        });

        // redirect but don't add identity URL in history stack.
        // @see https://nextjs.org/docs/api-reference/next/router#routerreplace
        await router.replace(
          identityProviderSignOutUrl,
          identityProviderSignOutUrl,
          { locale: router.defaultLocale }
        );

        // unreachable
        // setSigningOut(false);
      }
    })();
  }, [refreshData, router, signingOut, identityProviderSignOutUrl]);

  if (!session && loading) {
    return null; // still loading - don't render yet.
  }

  if (signingOut) {
    return null; // signing out. postpone rendering until signing out effect completes.
  }

  const productName = i18n.hasResourceBundle(router.locale, 'common')
    ? t('product-name-mixedcase', {
        applyPostProcessor: false,
      })
    : null;

  if (!productName) {
    // resource bundle not loaded.
    refreshData();
    return null;
  }

  // BEYOND THIS POINT THE SESSION, IF ANY ACTIVE, IS LOADED

  if (!session /* logged out */) {
    if (nextLocaleCookies) {
      deleteCookie();
      refreshData();
      return null;
    }
  }

  dataRefreshed();

  // AT THIS POINT, LICENCE CHECKS AND LOCALE SETUP HAVE SETTLED.

  if (session) {
    Sentry.setUser({ email: session.user.email }); // WARN: PII stored in Sentry.
    Sentry.setTag('page_locale', i18n.language);
  }

  return (
    <SWRConfig
      value={{
        fetcher,
      }}
    >
      {!session && <SignIn buildId={buildId} />}
      {session && (
        <MainPage
          productName={productName}
          user={session.user as AutodeskUser}
          buildId={buildId}
          signOutCallback={setSigningOut}
        />
      )}
    </SWRConfig>
  );
}

export default Page;
