import type { Site } from '@/common/types';

export enum SitesDisplayStatus {
  ERROR,
  EMPTY,
  LOADING,
  LOADED,
}

export const getSitesDisplayStatus = (
  sites: Array<Site> | Error
): SitesDisplayStatus => {
  if (sites instanceof Error) {
    return SitesDisplayStatus.ERROR;
  }

  if (!sites) {
    return SitesDisplayStatus.LOADING;
  }

  if (sites.length === 0) {
    return SitesDisplayStatus.EMPTY;
  }

  return SitesDisplayStatus.LOADED;
};
