import { signIn } from 'next-auth/client';
import { useTranslation } from 'next-i18next';
import React from 'react';

import Footer from '@/components/footer';
import Logo from '@/components/logo';

import Button from '@sgds/component-button';
import { TextPresentation } from '@sgds/component-text';

import styles from './signIn.module.css';

const SignIn = ({ buildId }: { buildId: string }): JSX.Element => {
  const { t } = useTranslation(['signIn']);

  const platformNameLower = 'forge';

  return (
    <div>
      <div className={styles.containerOuter}>
        <div className={styles.containerInner}>
          <Logo />

          <TextPresentation className={styles.text}>
            {t('sign-in-detail')}
          </TextPresentation>
          <Button
            className={styles.button}
            onClick={() => signIn(platformNameLower)}
          >
            {t('sign-in')}
          </Button>
          <Footer buildId={buildId} />
        </div>
      </div>
    </div>
  );
};
export default SignIn;
