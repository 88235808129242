import { AxiosError } from 'axios';
import initials from 'initials';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { Dispatch, SetStateAction } from 'react';
import useSWR, { useSWRConfig } from 'swr';

import type { Site } from '@/common/types';
import { SitesResponse } from '@/common/types';
import { AutodeskUser } from '@/common/types/forge_provider';
import Footer from '@/components/footer';
import Logo from '@/components/logo';
import { SGDSAvatar } from '@/dynamics/SGDSAvatar';
import logger from '@/lib/logger';
import URLS from '@/lib/urls';

import Button from '@sgds/component-button';
import { TextPresentation } from '@sgds/component-text';

import LocalInstallComponent from './LocalInstallComponent';
import ProductPageComponent from './ProductPageComponent';
import SitesListComponent from './SitesListComponent';
import styles from './mainPage.module.scss';

// The product name is currently hardcoded since the API uses 'shotgrid' but the new product name is Flow Production Tracking
const sitesAPI = (): string => `${URLS.API_LIST_SITES_ROUTE}?product=shotgrid`;

const log = logger('MainPage');

const MainPage = ({
  productName,
  user,
  buildId,
  signOutCallback,
}: {
  productName: string;
  user: AutodeskUser;
  buildId: string;
  signOutCallback: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const { t, i18n } = useTranslation(['mainPage']);
  const router = useRouter();
  const { fetcher } = useSWRConfig();

  const response = useSWR<SitesResponse, AxiosError>(
    productName ? sitesAPI() : null,
    {
      revalidateOnFocus: false,
      fetcher,
      onError: (err, key) => {
        logger('SWR').error(`GET ${key}`, err.message);
      },
    }
  );

  const licenseResponse = useSWR(productName ? URLS.API_LICENSE_ROUTE : null, {
    revalidateOnFocus: false,
    fetcher,
    onError: (err, key) => {
      logger('SWR').error(`GET ${key}`, err);
    },
  });
  const [license, setLicense] = React.useState<boolean | Error>();
  const [sites, setSites] = React.useState<Array<Site> | AxiosError>();

  const onSignOutClicked = () => {
    signOutCallback(true);
  };

  // On mount
  React.useEffect(() => {
    (async () => {
      const { data, error } = response;

      // NOTE: isValidating is not handled here. Instead, SitesListComponent knows the site list is loading
      // when its argument is null.

      if (error) {
        const message = error.response?.data?.error || error.message;

        log.error(`GET ${sitesAPI()} Internal API failure:`, message);
        setSites(error);
      } else if (data?.sites) {
        setSites(data.sites);
      }
    })();
  }, [response, productName]);

  React.useEffect(() => {
    (async () => {
      const { data, error, isValidating } = licenseResponse;

      if (isValidating) {
        // wait until the result has stabilized.
        return;
      }

      if (error) {
        log.error(`GET /api/v1/license Internal API failure:`, error.message);
        setLicense(error);
      } else if (data) {
        setLicense(data as boolean);
      }
    })();
  }, [licenseResponse]);

  if (!i18n.hasResourceBundle(router.locale, 'mainPage')) {
    // wait for translation bundle to be loaded.
    return null;
  }

  const userInitials = initials(user.name) as string;

  return (
    <div>
      <div className={styles.containerOuter}>
        <div className={styles.containerInner}>
          <Logo />
          <div className={styles.userAvatar}>
            <SGDSAvatar
              alt={user.email}
              imageSource={user.image}
              initials={userInitials}
            />
          </div>
          <div className={styles.userEmail}>
            <TextPresentation>{user.email}</TextPresentation>
          </div>
          <div>
            <SitesListComponent sites={sites} />
          </div>
          <div>
            <LocalInstallComponent license={license} />
          </div>
          <div className={styles.buttonContainer}>
            <Button label={t('sign-out')} onClick={onSignOutClicked} />
            <ProductPageComponent sites={sites} hasLicense={license === true} />
          </div>
          <Footer buildId={buildId} />
        </div>
      </div>
    </div>
  );
};

export default MainPage;
