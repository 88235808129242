import { AxiosError } from 'axios';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

import type { Site } from '@/common/types';
import { SitesList, SitesNote } from '@/components/sites';

import { TextPresentation } from '@sgds/component-text';

import {
  SitesDisplayStatus,
  getSitesDisplayStatus,
} from '../../helpers/main_page_helpers';

import ErrorSitesList from './errorSitesList';
import styles from './mainPage.module.scss';

const SitesListComponent: FC<{
  sites: Array<Site> | AxiosError;
}> = ({ sites }) => {
  const status = getSitesDisplayStatus(sites);
  const { t } = useTranslation(['mainPage', 'footer']);

  switch (status) {
    case SitesDisplayStatus.ERROR:
      if (!(sites instanceof Error)) {
        // typescript type narrowing
        throw new Error('Unexpected');
      }
      return (
        <div className={styles.errorNoSite}>
          <ErrorSitesList error={sites} />
        </div>
      );
    case SitesDisplayStatus.LOADING:
      return (
        <div className={styles.loader}>
          <BeatLoader size={16} />
        </div>
      );
    case SitesDisplayStatus.EMPTY:
      return (
        <div className={styles.noSiteContainer}>
          <TextPresentation variant={TextPresentation.variants.BODY}>
            {t('no-site')}
          </TextPresentation>
          <div className={styles.noteContainer}>
            <div className={styles.noteFiller} style={{ float: 'left' }} />
            <SitesNote />
            <div className={styles.noteFiller} style={{ float: 'right' }} />
          </div>
        </div>
      );
    case SitesDisplayStatus.LOADED:
      if (sites instanceof Error) {
        throw new Error('Unexpected');
      }
      return <SitesList sites={sites} />;

    default:
      throw new Error('unexpected');
  }
};

export default SitesListComponent;
