import { AccessReleases, ErrorAccessReleases } from '../releases';

export default function LocalInstallComponent({
  license,
}: {
  license: boolean | Error;
}): JSX.Element {
  if (license === true) {
    return <AccessReleases />;
  }

  if (license instanceof Error) {
    return <ErrorAccessReleases />;
  }

  return <div />;
}
